import React, { useEffect, useRef } from "react";
import Header from "../Header";
import FirstSection from "./FirstSection";

import "./index.scss";
import AboutSection from "../AboutSection";
import CoreFeatures from "../CoreFeatures";
import CommunityCircle from "../CommunityCircle";

import InformationSection from "../Information";
import HelpSection from "../HelpSupport";
import Footer from "../Footer";

import GoldenBites from "../Golden-Bites";
import ProblemSolution from "../Problem-Solution";

function Home() {

  useEffect(() => {
    const userAgent = navigator.userAgent || (navigator.vendor as any) || (window as any).opera;

    // Redirect mobile users to native apps
    if (/android/i.test(userAgent)) {
        window.location.replace("https://play.google.com/store/apps/details?id=com.delnie.favhiker");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
        window.location.replace("https://apps.apple.com/in/app/favhiker/id6466083218");
    }
}, []);
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const coreRef = useRef(null);
  const communityRef = useRef(null);

  const rewardRef = useRef(null);
  const exploreRef = useRef(null);
  const infoRef = useRef(null);
  // const helpRef = useRef(null);
  // Add refs for other sections as needed

  // Function to handle smooth scrolling to a section
  const scrollToSection = (sectionRef: any) => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div>
        <div className="home-bg-container">
          <Header
            scrollToSection={scrollToSection}
            homeRef={homeRef}
            aboutRef={aboutRef}
            coreRef={coreRef}
            communityRef={communityRef}
            rewardRef={rewardRef}
            exploreRef={exploreRef}
            infoRef={infoRef}
            // helpRef={helpRef}
          />
          <div ref={homeRef} id="home">
            <FirstSection />
          </div>
        </div>
      </div>
      <div>
        <GoldenBites />
      </div>
      <div>
        <ProblemSolution />
      </div>
      <div ref={coreRef} id="core">
        <CoreFeatures />
      </div>
      <div ref={communityRef} id="community">
        <CommunityCircle />
      </div>
      {/* <div>
        <GoldenBites />
      </div> */}
      <div ref={aboutRef} id="about">
        <AboutSection />
      </div>
      {/* <div ref={helpRef} id="help">
        <HelpSection />
      </div> */}
      <div id="footer">
        <Footer />
      </div>
    </>
  );
}

export default Home;
